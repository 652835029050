import { getAllContracts } from '../services/api.js'

export const contracts = {
  state: () => ({
    contracts: []
  }),
  mutations: {
    setContracts (state, items) {
      state.contracts = items
    }
  },
  actions: {
    loadContracts({commit})  {
      return getAllContracts().then(response => {
        commit("setContracts", response.data[0].result.values)
        return response
      })
    },
  },
  getters: {
    getAllContracts: state => {
      return state.contracts
    }
  }
}
