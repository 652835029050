<template>
  <router-view />
<!--  <FormModalV3 />-->
</template>
<script>
import {ref, watch} from 'vue'
import { useStore } from 'vuex'
// import FormModalV3 from "./views/widgets/FormModalV3";

export default {
  // components: {FormModalV3},
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')

  },
  metaInfo: {
    title: 'Default Title',
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
