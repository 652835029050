import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from "../store"

let routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/configurator',
        name: 'Configurator',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/configurator/users',
        children: [
          {
            path: '/configurator/users',
            name: 'Users',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/users/UsersRoot'),
          },
          {
            path: '/configurator/clients',
            name: 'ClientsRoot',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
          {
            path: '/configurator/clientsv2',
            name: 'ClientsRootV2',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
          {
            path: '/configurator/templates',
            name: 'Templates',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
        ]
      },
      {
        path: '/cabinet/contract',
        name: 'CabinetContract',
        props:  route => (getProps1(route.path)),
        component: () => import('@/views/configurator/clients/ClientsTable'),
      },
      {
        path: '/configurator/requests',
        name: 'ConfiguratorRequests',
        props:  route => (getProps1(route.path)),
        component: () => import('@/views/configurator/clients/ClientsTable'),
      },
      {
        path: '/support/smc',
        name: 'Support',
        props:  route => (getProps1(route.path)),
        component: () => import('@/views/support/smc/SmcRoot'),
      },
      {
        path: '/support/blacklist',
        name: 'Blacklist',
        props:  route => (getProps1(route.path)),
        component: () => import('@/views/configurator/clients/ClientsTable'),
      },
      {
        name: 'SmsView',
        path: '/support/sms',
        props:  route => (getProps1(route.path)),
        component: () => import('@/views/configurator/clients/ClientsTable'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        props:  route => (getProps1(route.path)),
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/report',
        name: 'Reports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/report/templates',
        children: [
          {
            path: '/report/templates',
            name: 'Reports',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
          {
            path: '/report/main',
            name: 'Reports',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
          {
            path: '/report/base',
            name: 'ReportBase',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
          {
            path: '/report/platform',
            name: 'ReportsPlatform',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/configurator/clients/ClientsTable'),
          },
        ]
      },
      {
        path: '/tools',
        name: 'Tools',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/tools/tester',
        children: [
          {
            path: '/tools/tester',
            name: 'Tester',
            props:  route => (getProps1(route.path)),
            component: () => import('@/views/tools/Tester.vue'),
          },
        ]
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]



let router = createRouter({
  mode: 'history',
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_NAME !== 'asms' ? 'KCELL aCPA' : 'Управление шаблонами'
  if (to.name === 'Login') {
    if (store.getters.isLoggedIn) {
      next('/')
    } else {
      next()
    }
  } else {
    if (!store.getters.isLoggedIn) {
      next('/pages/login')
    } else {
      next()
    }
  }
})

function getProps1(path) {
  return getProps(store.getters.nav, path)
}

function getProps(nav, path) {
  for (var i = 0; i<nav.length; i++ ) {
    let item = nav[i]
    if (item.to === path && item.props) {
      return item.props
    } else if (item.items && item.items.length > 0) {
      let result = getProps(item.items, path)
      if (Object.keys(result).length>0) {
        return result
      }
    }
  }
  return {}
}

export default router
