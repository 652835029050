export const sygnet = [
  '1600 1600',
  `
<style type="text/css">
\t.st0{fill:#3A3B3C;}
\t.st1{fill:#5E6162;}
\t.st2{fill:#BEC2CA;}
\t.st3{fill:#E6E7E8;}
</style>
  <g id="layer101">
\t<path class="st0" d="M752.1,1567.9c-87.5-5.3-175.6-25.8-258.4-60.3c-8.5-3.5-19.9-8.5-25.3-11.1s-12.8-6.1-16.4-7.7
\t\tc-28-13.1-76.1-41.5-107-63.3c-22.6-15.9-55.9-42.5-74.7-59.6c-26-23.7-58.1-57.6-80.2-84.4c-24.6-30-51.2-68.6-71.6-104
\t\tC51.8,1061.4,17,931.3,17,797.5c0-184.3,65.7-359.7,187.4-501c67.7-78.5,153-144.1,247.1-190c21.8-10.6,25.3-12.3,38.5-17.7
\t\tc55.6-23.1,107-38.7,160.3-48.7C708,29.4,743.5,26,800.6,26c75,0,136.3,8.2,211.9,28.5c50.9,13.6,114,38.8,161,64.3
\t\tc28.6,15.5,64.8,37.8,85.3,52.6c54.3,39.3,98.1,79.2,139.7,127.6c86.1,99.9,145.5,221.9,170.9,350.9c10.2,51.4,13,83.8,13,147.1
\t\tc0,54.6-1.6,79.3-7.8,119c-20.4,131.4-75.9,255.9-161.2,362c-5.1,6.3-10.1,12.4-11.1,13.5s-3.1,3.6-4.7,5.5
\t\tc-13.4,16.3-55.6,59-75.4,76.3c-45.3,39.4-93.4,72.8-145.7,101.1c-16.5,8.9-21.1,11.2-43.5,21.8c-86.8,41-195.7,67.8-292,71.8
\t\tC815.5,1569.1,770.9,1569,752.1,1567.9z"/>
</g>
<g id="layer102">
\t<path class="st1" d="M752.1,1567.9c-87.5-5.3-175.6-25.8-258.4-60.3c-8.5-3.5-19.9-8.5-25.3-11.1s-12.8-6.1-16.4-7.7
\t\tc-28-13.1-76.1-41.5-107-63.3c-22.6-15.9-55.9-42.5-74.7-59.6c-22.2-20.4-54.1-53.3-72.2-74.9c-16.1-19.1-33.4-41.8-46.6-61
\t\tl-5.8-8.5l8.5,8.2c4.7,4.5,10,8.9,11.7,9.7c3.1,1.6,3.1,1.6,3.1-1c0-3.2-5.6-29.4-12.1-56.9c-18.3-76.3-25.1-130.3-25.3-198.5
\t\tc-0.1-66.6,5.4-115.7,19.9-175.5c4.8-20,18.3-65.5,21.6-73.1c1.1-2.7,4.8-11.6,8.1-19.9c23.1-57.5,53.9-112,92.3-163.5
\t\tc50.2-67.2,115.6-125.4,192.5-171.5c20.7-12.3,60.8-32.9,80-40.9c6.3-2.6,16.7-7.2,23-10.1c16-7.4,27.4-10.9,73.7-22.5
\t\tc50.4-12.7,170.5-28.5,191.3-25.2l4.5,0.7l-0.3,9c-0.3,6.8-1.1,11.1-3.4,17.5c-7.9,22.4-19.6,60.9-21.8,71.3
\t\tc-1.1,5.5-1.1,5.8,1.2,7.7c1.3,1.1,11.1,6.5,21.8,11.9c28.9,14.7,45.5,26.3,67.7,47.5c29.3,28,51.3,57.6,68.7,92.6
\t\tc12.1,24.4,16.1,29.6,22.4,28.8c5-0.7,66.6-26.7,206.2-87c18.4-8,48.4-20.7,66.5-28.3c49.6-20.6,56.5-23.6,75-32.4
\t\tc9.4-4.4,19.7-8.7,23-9.6c6.4-1.6,17.5-2,17.5-0.6c0,1.5-3.2,4.7-11.6,11.8c-4.4,3.7-13,11.3-19,16.8S1330,458.2,1316,470
\t\tc-81.2,68.6-254.2,224.6-264.2,238.4c-7.1,9.8-2.2,23.5,38.7,108.6c23.8,49.5,65.2,136.5,70.5,148c2.9,6.3,8.4,18.1,12.2,26.2
\t\tc5.5,11.8,7.6,17.8,10.7,30.5c5.5,22.1,6.5,29.4,6.4,48.3c0,31.5-6.1,51.6-23.5,78c-10.7,16.2-21.1,26.5-38.7,38.4
\t\tc-21.6,14.5-35.4,18.1-74.1,19.3c-47.5,1.4-48.8,0.8-142.5-60.1c-88.7-57.6-137.8-87.8-163-100.2c-15.5-7.6-23.7-10.3-32.3-10.7
\t\tc-8-0.3-10.1,2.5-14.8,19.8c-22.1,81.7,25.1,217.4,107.7,309.2c45.5,50.7,112.4,89.8,179.1,104.7c30.3,6.8,63.3,9.6,92.5,7.8
\t\tc55.9-3.5,104.6-17.3,161.7-45.9c13.7-6.9,18.8-9.1,17.1-7.5c-2.6,2.5-34.4,23.4-49.3,32.4c-17.9,10.8-45.7,26-58.7,32.3
\t\tc-22.1,10.5-30.4,14.3-46.5,21c-83.2,34.6-177.4,55.9-264,59.5C815.5,1569.1,770.9,1569,752.1,1567.9z M877.7,779.5
\t\tc4.7-3.3,5.3-15.6,1.7-33.5c-4.8-23.6-22.2-42.2-44.5-47.5c-15-3.5-28.9-1.3-46.7,7.5c-9.9,4.9-12.8,6.9-15.2,10.2
\t\tc-4.1,5.7-3.9,8,1.6,13.8c7.2,7.7,33.1,23.3,64.9,39C862,780.2,872.6,783.1,877.7,779.5z"/>
\t<path class="st2" d="M61.5,541c1-3.6,11.6-31.2,12.6-33c1.9-3.4,0.6,0.7-5.7,17.2C62.4,541,59.6,547.3,61.5,541z"/>
</g>
<g id="layer103">
\t<path class="st3" d="M752.1,1567.9c-87.5-5.3-175.6-25.8-258.4-60.3c-8.5-3.5-19.9-8.5-25.3-11.1s-12.8-6.1-16.4-7.7
\t\tc-27.9-13-76.3-41.6-107-63.3c-22.5-15.8-56-42.6-75-59.9c-25.8-23.6-61.3-61-81-85.5c-15.4-19.1-38.5-50.4-39.7-53.9
\t\tc-0.4-0.9,3,1.7,7.4,5.9c8.3,7.9,11.7,9.9,13.6,8c1.8-1.8-0.7-17.7-5.3-34.1c-16.9-60.4-24.9-100-29.9-149
\t\tc-0.7-5.8-1.4-12.5-1.6-14.9c-1.9-16.9-2.6-75.6-1.2-97.1c0.8-12.2,3.1-35.1,4.7-47.1c6-45.2,23.7-128.1,33.3-155.9
\t\tc1.5-4.3,4.3-11,9.1-21.5c1.3-2.7,3.6-8.4,5.1-12.5s3.3-8.9,4.1-10.7c0.8-1.7,1.4-3.3,1.4-3.6c0-0.4,4.6-10.4,14-30.3
\t\tc4.4-9.3,19.4-36.9,25.3-46.4c30.4-49.4,62-89.4,103.2-130.5c52.1-52.1,110.3-94.8,172.5-126.6c33.3-17.1,69.2-34.1,81-38.3
\t\tc34-12.3,133.1-30.9,194.5-36.6c15.1-1.4,36.6-1.2,44.8,0.4c8.7,1.7,12.1,4.1,12.5,8.9c0.4,4.9-3.9,18.6-10.3,32.2
\t\tc-5.7,12.4-15.5,35.7-16.9,40.5c-2.3,7.7-2.1,12.9,0.8,17.1c1.9,2.8,6,5.4,19.7,12.4c30.2,15.7,50.9,30.6,74.8,53.9
\t\tc29,28.5,49,56,68.2,93.8c4.4,8.8,9.5,17.5,11.4,19.5c4.4,4.6,9.5,5.3,18.3,2.4c4.9-1.7,64.7-27.4,71.9-30.9c1-0.5,3.6-1.7,5.8-2.7
\t\tc2.2-0.9,5.8-2.5,8-3.5c3.5-1.6,7.3-3.2,14-5.9c15.3-6.2,24.3-10,30.5-12.8c4.1-1.9,8.9-4,10.5-4.7c1.7-0.7,4.8-2.1,7-3
\t\tc7.9-3.5,10.2-4.5,25-10.6c8.3-3.4,16.6-7,18.5-7.9c5.4-2.7,9.5-4.5,22-9.6c16.3-6.6,34.3-14.6,35.5-15.6c0.5-0.5,1.5-0.9,2.3-0.9
\t\tc0.7,0,3.7-1.1,6.5-2.4c2.9-1.3,6.8-2.9,8.7-3.6c4.6-1.7,13.8-5.7,17.3-7.5c1.6-0.8,3.3-1.5,3.8-1.5c0.6,0,3.6-1.2,6.7-2.6
\t\tc3.1-1.5,6.6-3,7.7-3.5c1.1-0.4,5.5-2.4,9.8-4.3c4.4-2,8.1-3.6,8.3-3.6c0.2,0,6.1-2.7,13.1-6.1c25.5-12.1,27.9-13,38.1-13.7
\t\tc9.6-0.6,10.2,0.2,4.5,5.6c-2.1,1.9-17,14.3-33.2,27.6c-16.2,13.2-35.1,29.1-42,35.1c-6.9,6.1-21.1,18.4-31.6,27.5
\t\tc-30.1,26.1-56.1,49-67.7,59.6c-24.5,22.6-62.9,57.5-86.8,79c-29,26-60.8,57.2-66.7,65.4c-6.6,9.1-6.7,9.4-4.7,15.3
\t\tc3.4,10.5,16.2,38.4,41.2,89.7c24,49.4,32.3,66.8,52.2,109c4.5,9.6,11.1,23.4,14.7,30.5c18.4,37.2,24.8,51.1,26.7,57.6
\t\tc11.3,39.3,8.7,95.1-5.6,121.9c-17.8,33.2-41.2,54.1-72.3,64.3c-17.7,5.9-33.2,7.9-59.5,7.9c-25.2,0-35-1.9-51.5-9.7
\t\tc-8.7-4.2-45.8-28.3-101.5-66c-23.1-15.7-38-24.7-43.5-26.5c-2.2-0.7-4.6-1.7-5.4-2.3c-0.7-0.6-2.2-1.6-3.2-2.2
\t\tc-1-0.5-9.2-6-18.2-12.2c-14-9.6-38.7-24.1-49.2-29c-1.6-0.7-5-2.5-7.5-4c-16.5-9.5-28.3-15.4-36.9-18.3
\t\tc-6.6-2.2-19.7-4.3-21.2-3.5c-1.5,1-34,0.6-46.1-0.5c-40.3-3.7-68.5-9.9-97.8-21.7C498.9,984,453.9,938,427.7,872
\t\tc-11.1-27.8-16.6-68.6-15.4-113.5c0.9-33.9,4-51.8,12.2-72.4c2.5-6.1,4.5-11.4,4.5-11.8c0-1.6-5.7,4.9-10.6,12.3
\t\tc-9.7,14.6-23.7,45.7-32.5,72.5c-12.2,37.1-14.4,57.3-14.4,131.4c0,53.3,0.8,71,4.5,105.8c3.7,33.9,8,52.7,19.9,87.7
\t\tc11,32.5,19.9,53.7,35.2,84c67.1,133.3,177.7,235.2,314.4,290c35.3,14.1,78.1,26.7,108.5,31.9c62.4,10.7,168.7,9.3,230.5-3
\t\tc14.7-2.9,23.1-5.9,27.6-9.7c3.7-3.2,4.2-3.3,9.3-2.7c13.8,1.7,63.3-15.1,106.1-36c11.6-5.6,22.1-10.6,23.5-11l2.5-0.7l-2.5,1.9
\t\tc-14.9,11.8-72.8,46-99.5,58.8c-22.1,10.5-30.4,14.3-46.5,21c-83.2,34.6-177.4,55.9-264,59.5C815.5,1569.1,770.9,1569,752.1,1567.9
\t\tz M879.5,784.9c4.3-8.3,5.8-29.6,3-41.7c-2.6-10.9-6.2-16.9-15.7-26.4c-4.8-4.9-9.2-8.8-9.7-8.8c-0.6,0-3.2-1.4-5.8-3
\t\tc-11.9-7.6-36.2-11.2-50.2-7.5c-9.5,2.4-17.4,6.8-23.5,13c-8.4,8.6-10.3,14-6.6,18.8c2.1,2.6,13.6,10.7,18.1,12.7
\t\tc1.3,0.6,4.2,2.1,6.4,3.5c2.2,1.3,5.6,3.2,7.5,4.3c1.9,1,6.2,3.4,9.5,5.2s8.7,4.7,12,6.5c3.3,1.7,7.6,4.1,9.5,5.3
\t\tc8.2,4.9,40.6,21.2,42.2,21.2C877.2,788,878.7,786.6,879.5,784.9z"/>
</g>
`,
]
