import {getCurrency} from '../services/api.js'

export const currency = {
  state: () => ({
    currency: []
  }),
  mutations: {
    setCurrency (state, items) {
      state.currency = items
    }
  },
  actions: {
    loadCurrency({commit})  {
      return getCurrency().then(response => {
        commit("setCurrency", response.data[0].result.values)
        return response
      })
    },
  },
  getters: {
    getAllCurrency: state => {
      return state.currency
    },
    getCurrency: state => id => {
      let item = state.currency.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0]
      }
      return null
    },
    getCurrencyName: state => id => {
      let item = state.currency.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0].CurrencyName
      }
      return null
    }
  }
}
