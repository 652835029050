import { getUserFunctions} from '../services/api.js'

export const userFunctions = {
  state: () => ({
    items: ""
  }),
  mutations: {
    setFunctionItems (state, items) {
      state.items = items
    }
  },
  actions: {
    loadUserFunctions({commit})  {
      return getUserFunctions().then(response => {
        commit("setFunctionItems", response.data[0].result)
        return response
      })
    },
  },
  getters: {
    getUserFunctions: state => {
      return state.items
    }
  }
}
