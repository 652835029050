import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from "@coreui/vue-pro";
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import visibility from 'vue-visibility-change';
import {pingUser} from "./services/api";
import SimpleTypeahead from 'vue3-simple-typeahead';
import Toaster from "@meforma/vue-toaster";

import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';




const app = createApp(App)
app.use(visibility)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(SimpleTypeahead)
app.use(Toaster, {
  position: 'top'
})
app.use(VCalendar, {})

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)


app.mount('#app')

visibility.change((evt, hidden) => {
  if (!hidden) {
    pingUser().then()
  }
});

window.addEventListener("pageshow", event => {
  pingUser().then()
})
