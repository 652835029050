import {getPlatform} from '../services/api.js'

export const platform = {
  state: () => ({
    platform: []
  }),
  mutations: {
    setPlatform (state, items) {
      state.currency = items
    }
  },
  actions: {
    loadPlatform({commit})  {
      return getPlatform().then(response => {
        commit("setPlatform", response.data[0].result.values)
        return response
      })
    },
  },
  getters: {
    getAllPlatform: state => {
      return state.platform
    },
    getPlatform: state => id => {
      let item = state.platform.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0]
      }
      return null
    },
    getPlatformName: state => id => {
      let item = state.platform.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0].Name
      }
      return null
    }
  }
}
