import {getServicePrice} from '../services/api.js'

export const servicePrice = {
  state: () => ({
    servicePrice: []
  }),
  mutations: {
    setServicePrice (state, items) {
      state.servicePrice = items
    }
  },
  actions: {
    loadServicePrice({commit})  {
      return getServicePrice().then(response => {
        commit("setServicePrice", response.data[0].result.values)
        return response
      })
    },
  },
  getters: {
    getAllServicePrice: state => {
      return state.servicePrice
    },
    getServicePrice: state => id => {
      let item = state.servicePrice.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0]
      }
      return null
    },
    getServicePriceName: state => id => {
      let item = state.servicePrice.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0].PriceName
      }
      return null
    }
  }
}
