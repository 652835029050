import axios from 'axios';
import store from '../store'
import logoutUseCase from "../domain/use_case/logoutUseCase";

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API,
})

api.interceptors.request.use(
  config => {
    if (store.state.auth.session_id) {
      config.headers['X-Session-Id'] = store.state.auth.session_id;
    }
    return config;
  }
);

api.interceptors.response.use(
  response => {

    // if (response.data[0].result.session_id) {
    //   sessionId = response.data[0].result.session_id
    //   console.log(sessionId)
    // }
    return response;
  },
  error => {
    if (error.response.status === 403 || error.response.status === 401) {
      logoutUseCase()
    } else {
      throw error
    }
  }
);


function auth(login, password) {
  return sendRequest(
    'User',
    'Auth',
    {
      'login': login,
      'password': password
    }
  )
}

function getUsers() {
  return sendRequest(
    'User',
    'index',
  )
}

function pingUser() {
  return sendRequest(
    'User',
    'ping',
  )
}

function addUser(data) {
  return sendRequest("user", "userAdd", data)
}

function editUser(data) {
  return sendRequest("user", "userEdit", data)
}

function deleteUser(data) {
  return sendRequest(
    'User',
    'userDel',
    data
  )
}

function getUserRoles() {
  return sendRequest(
    //action: 'Reference'
    'User',
    'role',
  )
}

function addUserRole(data) {
  return sendRequest("user", "roleAdd", data)
}

function editUserRole(data) {
  return sendRequest("user", "roleEdit", data)
}

function deleteUserRole(data) {
  return sendRequest("user", "roleDel", data)
}

function getUserFunctions() {
  return sendRequest("user", "func")
}

function addUserFunction(data) {
  return sendRequest("user", "funcAdd", data)
}

function editUserFunction(data) {
  return sendRequest("user", "funcEdit", data)
}

function deleteUserFunction(data) {
  return sendRequest("user", "funcDel", data)
}

function getSmc() {
  return sendRequest("smc", "index")
}

function getSmcActive(number) {
  return sendRequest("smc", "active", {msisdn: number})
}

function getSmcInactive(number) {
  return sendRequest("smc", "inactive", {msisdn: number})
}

function getSmcOnetime(number) {
  return sendRequest("smc", "purchase", {msisdn: number})
}

function closeSmc(id, number) {
  return sendRequest("smc", "close", {msisdn: number, id: id})
}

function getSmcCharge(number) {
  return sendRequest("smc", "charge", {msisdn: number})
}

function getSmcSms(data) {
  return sendRequest("smc", "sms", data)
}

function getSmcSmpp(number) {
  return sendRequest("smc", "smpp", {msisdn: number})
}

function getSmcLog(number) {
  return sendRequest("smc", "log", {msisdn: number})
}

function banSmcUser(number) {
  return sendRequest("smc", "blAdd", {msisdn: number})
}

function checkTester(template, text) {
  return sendRequest("tester", "check", {template: template, text: text})
}

function getClients() {
  return sendRequest("client", "index")
}

function deleteClients(data) {
  return sendRequest("client", "del", {ID: data})
}

function getClientContracts(id) {
  return sendRequest("client", "contracts", {ID: id})
}

function getAllContracts() {
  return sendRequest("client", "getContracts")
}

function getCurrency() {
  return sendRequest("currency", "index")
}

function getPlatform() {
  return sendRequest("platform", "index")
}

function getServicePrice() {
  return sendRequest("servicePrice", "index")
}

function getServiceType() {
  return sendRequest("serviceType", "index")
}

function getCarrier() {
  return sendRequest("serviceCarrier", "index")
}

function getCenter() {
  return sendRequest("center", "index")
}

function getDashboard(period) {
  return sendRequest("dashboard", "index", {period: period})
}
function downloadBlob(controller, method, data) {
  return sendRequest(controller, method, data, true)
}

function uploadTemplatesLoad(controller, method, data) {
  let bodyFormData = new FormData()
  let formData = {...data}

  for (const [key, value] of Object.entries(data)) {
    if (value instanceof File) {
      bodyFormData.append(key, value);
      delete formData[key];
    }
  }

  let request = encodeURIComponent(JSON.stringify(buildData(controller, method, formData)))
  bodyFormData.append('request', request);

  return axios({
    method: "post",
    url: process.env.VUE_APP_API + "router/",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      'X-Session-Id': store.state.auth.session_id
    },
  })
}


function sendRequest(controller, method, data, isBlob = false) {
  if (!data) {
    data = {}
  }
  let containsFile = false

  for (const [key, value] of Object.entries(data)) {
    if (value instanceof File) {
      containsFile = true
    }
  }

  if (containsFile) {
    return uploadTemplatesLoad(controller, method, data)
  } else {
    const config = isBlob ? { responseType: 'blob' } : {}
    return api.post('router/', buildData(controller, method, data), config)
  }
}

function buildData(controller, method, data) {
  return {
    'controller': controller,
    'method': method,
    'data': data,
    'type': 'rpc',
    'id': uuidv4(),
  }
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


export {
  sendRequest,

  auth,
  getUsers,
  addUser,
  editUser,
  deleteUser,

  getUserRoles,
  addUserRole,
  editUserRole,
  deleteUserRole,

  getUserFunctions,
  addUserFunction,
  editUserFunction,
  deleteUserFunction,

  getSmc,
  getSmcActive,
  getSmcInactive,
  getSmcOnetime,
  closeSmc,

  getSmcCharge,
  getSmcSms,
  getSmcSmpp,
  getSmcLog,
  banSmcUser,

  checkTester,

  getClients,
  getClientContracts,
  getAllContracts,
  deleteClients,

  getCurrency,

  getPlatform,

  getServiceType,
  getServicePrice,
  getCarrier,
  getCenter,
  getDashboard,
  pingUser,
  downloadBlob
}
