
export const formModalState = {
  state: () => ({
    isVisible: false
  }),
  mutations: {
    setVisible (state, visible) {
      state.isVisible = visible
    }
  },
  actions: {
    showModal() {
      this.commit("setVisible", true)
    },
    hideModal() {
      this.commit("setVisible", false)
    }
  },
  getters: {
    isVisible: state => {
      return state.isVisible
    },
  }
}
