import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {auth} from "./auth";
import {navigation} from "./navigation";
import {userFunctions} from "./functions";
import {userRoles} from "./roles";
import {currency} from "./currency";
import {platform} from "./platform";
import {servicePrice} from "./servicePrice";
import {serviceType} from "./serviceType";
import {center} from "./center";
import {contracts} from "./contracts";
import {formModalState} from "./view_state/formModalState";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    hideSidebar(state) {
      state.sidebarVisible = false
    },
    showSidebar(state) {
      state.sidebarVisible = true
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    }
  },
  actions: {
    hideSidebar() {
      commit("hideSidebar")
    }
  },
  modules: {
    formModalState: formModalState,
    auth: auth,
    navigation: navigation,
    userFunctions: userFunctions,
    userRoles: userRoles,
    currency: currency,
    platform: platform,
    servicePrice: servicePrice,
    serviceType: serviceType,
    center: center,
    contracts: contracts
  },
})
