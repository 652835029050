<template>
  <CSidebar
    ref="sidebar"
    position="fixed"
    selfHiding="md"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="visibility"
  >
    <CSidebarBrand>
      <CIcon
        customClassName="sidebar-brand-full"
        :icon="logo"
        :height="35"
      >
      </CIcon>
      <CIcon
        customClassName="sidebar-brand-narrow"
        :icon="icon"
        :height="35"
      />
      <CBadge
        v-if="isBetaBadgeVisible"
        shape="rounded-pill"
        color="info-gradient"
        class="position-absolute top-0 end-0"
      >
        beta
      </CBadge>
    </CSidebarBrand>
    <AppSidebarNav/>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {AppSidebarNav} from './AppSidebarNav'
import {logoNegative} from '@/assets/brand/logo-negative'
import {sygnet} from '@/assets/brand/sygnet'
import {logoSms} from '@/assets/brand/logo-sms'
import {logoSquareSms} from '@/assets/brand/logo-square-sms'

const isOnMobile = (element) =>
  Boolean(getComputedStyle(element).getPropertyValue('--cui-is-mobile'))
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    const icon = process.env.VUE_APP_LOGO === 'logo-sms' ? logoSquareSms : sygnet
    const logo = process.env.VUE_APP_LOGO === 'logo-sms' ? logoSms : logoNegative
    const isBetaBadgeVisible = process.env.VUE_APP_NAME !== 'asms'

    setInterval(function () {

    }, 1000)
    return {
      logo,
      icon,
      isBetaBadgeVisible,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  data() {
    return {
      // sidebarLeft: 0
    }
  },
  watch: {
    //TODO: бага на винде в режиме телефона не исчезает подложка вот костыль радуйся )
    sidebarVisible: function (val) {
      if (val) {
        let hideFunc = this.hide
        setTimeout(function () {
          let element = document.querySelector("div.sidebar")
          console.log("isOnMobile", isOnMobile(element))
          if (element && isOnMobile(element)) {
            console.log("addEventListener")
            window.addEventListener('mouseup', hideFunc)
          }
        }, 100)
      }

    }
  },
  methods: {
    visibility(event) {
      this.$store.commit({
        type: 'updateSidebarVisible',
        value: event,
      })
    },
    hide() {
      window.removeEventListener('mouseup', this.hide)

      let element = document.querySelector("div.fade.sidebar-backdrop.show")
      console.log(element)
      if (element !== null) {
        this.$store.commit('toggleSidebar')

        element.classList.remove("show")
        element.style.visibility = 'hidden'
        console.log("removed")
      }
    }
  },
}
</script>
