export const logoNegative = [
  '1675 270',
  `
<style type="text/css">
\t.st01{fill:#3A3B3C;}
\t.st11{fill:#5E6162;}
\t.st21{fill:#BEC2CA;}
\t.st31{fill:#E6E7E8;}
\t.st41{fill:none;}
\t.st51{fill:#FFFFFF;}
</style>
<g id="layer101">
\t<path class="st01" d="M130.3,269.6c-15.3-0.9-30.7-4.5-45.2-10.6c-1.5-0.6-3.5-1.5-4.4-2c-1-0.5-2.3-1.1-2.9-1.4
\t\tc-4.9-2.3-13.3-7.3-18.7-11.1c-4-2.8-9.8-7.4-13.1-10.4c-4.6-4.1-10.1-10.1-14-14.8c-4.3-5.3-8.9-12-12.5-18.2
\t\tC7.9,181,1.8,158.3,1.8,134.9c0-32.3,11.5-62.9,32.8-87.6C46.4,33.5,61.4,22,77.8,14c3.8-1.9,4.4-2.2,6.8-3.1
\t\tc9.8-4.1,18.7-6.8,28.1-8.6C122.8,0.4,129-0.2,139-0.2c13.1,0,23.9,1.4,37.1,5c8.9,2.4,20,6.8,28.1,11.3c5,2.7,11.3,6.6,14.9,9.2
\t\tc9.5,6.9,17.2,13.9,24.5,22.4c15.1,17.5,25.4,38.8,29.9,61.4c1.8,9,2.3,14.6,2.3,25.7c0,9.5-0.3,13.9-1.4,20.8
\t\tc-3.6,23-13.3,44.8-28.2,63.3c-0.9,1.1-1.8,2.2-2,2.3c-0.2,0.2-0.5,0.6-0.8,1c-2.3,2.9-9.8,10.4-13.2,13.4
\t\tc-8,6.9-16.4,12.8-25.5,17.7c-2.9,1.6-3.7,2-7.6,3.8c-15.2,7.2-34.2,11.9-51.1,12.5C141.4,269.8,133.6,269.8,130.3,269.6z"/>
</g>
<g id="layer102">
\t<path class="st11" d="M130.3,269.6c-15.3-0.9-30.7-4.5-45.2-10.6c-1.5-0.6-3.5-1.5-4.4-2c-1-0.5-2.3-1.1-2.9-1.4
\t\tc-4.9-2.3-13.3-7.3-18.7-11.1c-4-2.8-9.8-7.4-13.1-10.4c-3.9-3.6-9.5-9.3-12.6-13.1c-2.9-3.4-5.9-7.3-8.2-10.7l-1.1-1.5l1.5,1.4
\t\tc0.8,0.8,1.7,1.6,2,1.7c0.5,0.3,0.5,0.3,0.5-0.2s-1-5.2-2.1-10c-3.2-13.4-4.4-22.8-4.4-34.7c0-11.6,1-20.3,3.5-30.7
\t\tc0.8-3.5,3.2-11.5,3.8-12.8c0.2-0.5,0.8-2,1.4-3.5c4.1-10.1,9.5-19.6,16.1-28.6c8.8-11.8,20.3-21.9,33.7-30
\t\tc3.6-2.2,10.7-5.8,14-7.1c1.1-0.5,2.9-1.3,4.1-1.8c2.8-1.3,4.8-1.9,12.9-3.9c8.9-2.3,29.9-5,33.5-4.4l0.8,0.2l-0.1,1.6
\t\tc-0.1,1.2-0.2,2-0.6,3.1c-1.4,3.9-3.5,10.7-3.8,12.5c-0.2,1-0.2,1.1,0.2,1.4c0.2,0.2,2,1.1,3.8,2.1c5,2.6,8,4.6,11.9,8.3
\t\tc5.1,4.9,9,10.1,12,16.2c2.1,4.3,2.9,5.2,3.9,5c0.9-0.2,11.6-4.7,36.1-15.2c3.2-1.4,8.5-3.6,11.6-5c8.7-3.6,9.9-4.1,13.1-5.7
\t\tc1.7-0.8,3.5-1.5,4.1-1.7c1.1-0.3,3.1-0.4,3.1-0.1c0,0.2-0.5,0.8-2,2.1c-0.8,0.7-2.3,2-3.3,2.9s-3.9,3.5-6.4,5.5
\t\tc-14.2,12-44.5,39.3-46.2,41.7c-1.3,1.7-0.4,4.1,6.8,19c4.1,8.6,11.4,23.9,12.3,25.9c0.5,1.1,1.5,3.2,2.1,4.6
\t\tc1,2.1,1.4,3.2,1.9,5.3c1,3.9,1.1,5.2,1.1,8.5c0,5.5-1.1,9-4.1,13.7c-1.9,2.9-3.7,4.7-6.8,6.8c-3.8,2.6-6.2,3.2-13,3.4
\t\tc-8.3,0.2-8.6,0.2-24.9-10.5c-15.5-10.1-24.1-15.4-28.5-17.6c-2.7-1.4-4.1-1.8-5.6-1.9c-1.4-0.1-1.8,0.5-2.6,3.5
\t\tc-3.9,14.3,4.4,38,18.8,54.1c8,8.9,19.7,15.7,31.4,18.3c5.3,1.2,11.1,1.7,16.2,1.4c9.8-0.6,18.3-3,28.3-8c2.4-1.2,3.3-1.6,3-1.3
\t\tc-0.5,0.5-6,4.1-8.6,5.7c-3.2,1.9-8,4.6-10.3,5.6c-3.9,1.8-5.3,2.5-8.1,3.7c-14.6,6.1-31.1,9.8-46.2,10.4
\t\tC141.4,269.8,133.6,269.8,130.3,269.6z M152.3,131.7c0.8-0.6,0.9-2.7,0.3-5.9c-0.8-4.1-3.9-7.4-7.8-8.3c-2.6-0.6-5-0.2-8.2,1.3
\t\tc-1.7,0.8-2.3,1.2-2.6,1.8c-0.8,1-0.7,1.4,0.3,2.4c1.3,1.4,5.8,4.1,11.3,6.8C149.5,131.8,151.4,132.4,152.3,131.7z"/>
\t<path class="st21" d="M9.5,90c0.2-0.6,2-5.5,2.2-5.8c0.3-0.6,0.1,0.2-1,3C9.7,90,9.2,91.1,9.5,90z"/>
</g>
<g id="layer103">
\t<path class="st31" d="M130.3,269.6c-15.3-0.9-30.7-4.5-45.2-10.6c-1.5-0.6-3.5-1.5-4.4-2c-1-0.5-2.3-1.1-2.9-1.4
\t\tc-4.9-2.3-13.4-7.3-18.7-11.1c-3.9-2.8-9.8-7.4-13.1-10.5c-4.5-4.1-10.7-10.7-14.2-14.9c-2.7-3.4-6.8-8.9-7-9.5
\t\tc-0.1-0.2,0.5,0.3,1.3,1.1c1.4,1.4,2,1.7,2.4,1.4c0.3-0.3-0.2-3.1-0.9-6c-2.9-10.6-4.4-17.5-5.3-26c-0.2-1.1-0.2-2.2-0.3-2.6
\t\tc-0.3-2.9-0.5-13.2-0.2-17c0.2-2.1,0.5-6.2,0.8-8.3c1.1-7.9,4.1-22.4,5.9-27.2c0.2-0.8,0.8-2,1.6-3.8c0.2-0.5,0.6-1.5,0.9-2.2
\t\tc0.2-0.8,0.6-1.6,0.8-1.9s0.2-0.6,0.2-0.6c0-0.1,0.8-1.8,2.5-5.3c0.8-1.7,3.4-6.5,4.4-8.1c5.3-8.6,10.9-15.6,18.1-22.8
\t\tc9.1-9.1,19.3-16.6,30.2-22.1c5.9-3,12.1-6,14.2-6.7c5.9-2.2,23.3-5.4,34-6.4c2.6-0.2,6.4-0.2,7.8,0.1c1.5,0.3,2.1,0.8,2.2,1.6
\t\tc0.1,0.8-0.7,3.2-1.8,5.6c-1,2.2-2.7,6.2-2.9,7.1c-0.4,1.4-0.4,2.3,0.2,3c0.3,0.5,1.1,1,3.5,2.2c5.3,2.8,8.9,5.3,13.1,9.5
\t\tc5.1,5,8.6,9.8,11.9,16.4c0.8,1.6,1.7,3.1,2,3.4c0.8,0.8,1.7,0.9,3.2,0.5c0.8-0.3,11.3-4.8,12.6-5.4c0.2-0.1,0.6-0.3,1.1-0.5
\t\tc0.4-0.2,1.1-0.5,1.4-0.6c0.6-0.3,1.3-0.5,2.5-1.1c2.7-1.1,4.3-1.7,5.3-2.3c0.8-0.3,1.6-0.7,1.8-0.8c0.3-0.2,0.8-0.4,1.2-0.5
\t\tc1.4-0.6,1.8-0.8,4.4-1.9c1.4-0.6,2.9-1.2,3.2-1.4c1-0.5,1.7-0.8,3.8-1.7c2.9-1.1,6-2.6,6.2-2.7c0.1-0.1,0.2-0.2,0.4-0.2
\t\ts0.7-0.2,1.1-0.5c0.5-0.2,1.2-0.5,1.5-0.6c0.8-0.3,2.4-1,3-1.3c0.3-0.2,0.6-0.2,0.7-0.2c0.1,0,0.6-0.2,1.2-0.5
\t\tc0.5-0.2,1.1-0.5,1.4-0.6c0.2-0.1,1-0.5,1.7-0.8c0.8-0.4,1.4-0.6,1.4-0.6s1.1-0.5,2.3-1.1c4.4-2.1,4.9-2.3,6.7-2.4
\t\tc1.7-0.1,1.8,0,0.8,1c-0.4,0.3-3,2.5-5.8,4.8c-2.9,2.3-6.2,5.1-7.4,6.2s-3.7,3.2-5.6,4.8c-5.3,4.6-9.8,8.6-11.9,10.4
\t\tc-4.3,4-11,10.1-15.2,13.8c-5.1,4.6-10.7,10-11.7,11.4c-1.1,1.6-1.2,1.7-0.8,2.7c0.6,1.8,2.9,6.8,7.2,15.7
\t\tc4.2,8.6,5.6,11.7,9.2,19.1c0.8,1.7,2,4.1,2.6,5.3c3.2,6.5,4.4,8.9,4.7,10.1c2,6.9,1.5,16.7-1,21.3c-3.2,5.8-7.2,9.5-12.7,11.3
\t\tc-3.1,1.1-5.8,1.4-10.4,1.4c-4.4,0-6.2-0.3-9-1.7c-1.5-0.8-8-5-17.8-11.6c-4.1-2.8-6.7-4.4-7.6-4.7c-0.4-0.2-0.8-0.3-1-0.4
\t\tc-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.1-1.6-1.1-3.2-2.1c-2.5-1.7-6.8-4.2-8.6-5.1c-0.3-0.2-0.9-0.5-1.3-0.7c-2.9-1.7-5-2.7-6.5-3.2
\t\tc-1.1-0.4-3.5-0.8-3.7-0.6s-5.9,0.1-8-0.1c-7.1-0.7-12-1.7-17.1-3.8c-11-4.4-18.9-12.5-23.5-24c-2-4.9-2.9-12-2.7-19.9
\t\tc0.2-5.9,0.7-9.1,2.1-12.7c0.5-1.1,0.8-2,0.8-2.1c0-0.3-1,0.8-1.9,2.2c-1.7,2.6-4.1,8-5.7,12.7c-2.1,6.5-2.6,10.1-2.6,23
\t\tc0,9.3,0.2,12.5,0.8,18.5c0.7,5.9,1.4,9.2,3.5,15.3c2,5.7,3.5,9.4,6.2,14.7c11.7,23.3,31.1,41.1,55,50.7c6.2,2.5,13.7,4.7,19,5.6
\t\tc10.9,1.9,29.5,1.7,40.3-0.5c2.6-0.5,4.1-1.1,4.8-1.7c0.7-0.5,0.8-0.6,1.7-0.5c2.4,0.3,11.1-2.6,18.5-6.3c2-1,3.9-1.9,4.1-2
\t\tl0.5-0.2l-0.5,0.3c-2.6,2.1-12.8,8-17.4,10.3c-3.9,1.8-5.3,2.5-8.1,3.7c-14.6,6.1-31.1,9.8-46.2,10.4
\t\tC141.4,269.8,133.6,269.8,130.3,269.6z M152.6,132.7c0.8-1.4,1.1-5.2,0.5-7.3c-0.5-1.9-1.1-2.9-2.8-4.7c-0.8-0.8-1.6-1.6-1.7-1.6
\t\tc-0.1,0-0.5-0.2-1.1-0.5c-2.1-1.4-6.3-2-8.8-1.3c-1.7,0.5-3.1,1.2-4.1,2.3c-1.5,1.5-1.8,2.5-1.1,3.3c0.4,0.5,2.4,1.9,3.2,2.3
\t\tc0.2,0.1,0.8,0.4,1.1,0.6c0.4,0.2,1,0.5,1.3,0.8c0.3,0.2,1.1,0.6,1.7,0.9c0.6,0.3,1.5,0.8,2.1,1.1s1.4,0.8,1.7,0.9
\t\tc1.4,0.8,7.1,3.7,7.4,3.7C152.2,133.2,152.4,133,152.6,132.7z"/>
</g>
<polygon class="st41" points="173.8,46.2 176.2,46.2 176.2,46.2 "/>
<g>
\t<path class="st51" d="M320.6,120.4V53.2h17.3v64.1c0,16.8,7.8,26.1,23.8,26.1c16.4,0,27.1-11.6,27.1-29.4v-61h17.3v103.5h-17.3V142
\t\th-0.3c-5.5,9.6-16.1,16.7-32,16.7C333.7,158.8,320.6,144.1,320.6,120.4z"/>
\t<path class="st51" d="M428.5,53.2h17.3v14.7h0.3c5.5-9.6,16.1-16.7,32-16.7c22.7,0,35.8,14.7,35.8,38.3v67.3h-17.3V92.5
\t\tc0-16.8-7.8-26.1-23.8-26.1c-16.4,0-27.1,11.6-27.1,29.4v61h-17.3V53.2H428.5z"/>
\t<path class="st51" d="M534.9,24.7c0-6.1,4.9-11,11-11c6.1,0,11,4.7,11,11c0,6-4.7,11-11,11C539.8,35.6,534.9,30.8,534.9,24.7z
\t\t M537.2,53.2h17.3v103.5h-17.3V53.2z"/>
\t<path class="st51" d="M573.1,104.9L573.1,104.9c0-32.7,19-53.7,47.8-53.7c25.9,0,41.1,17.1,43.1,34.9l0.1,0.8h-16.9l-0.2-0.8
\t\tc-2.6-10.3-11-19.5-26.2-19.5c-18.1,0-30.1,14.9-30.1,38.4v0.1c0,24.1,12.3,38.3,30.3,38.3c14.1,0,23.3-7.9,26.1-19.5l0.2-0.8h17.1
\t\tl-0.1,0.7c-2.8,19.6-19.8,35-43.1,35C591.7,158.8,573.1,137.9,573.1,104.9z"/>
\t<path class="st51" d="M675.4,105.1v-0.2c0-33,19.3-53.6,48-53.6c28.6,0,48.1,20.6,48.1,53.6v0.2c0,33.1-19.3,53.9-48,53.9
\t\tC694.6,158.8,675.4,138,675.4,105.1z M753.5,105.1v-0.2c0-24.3-12-38.2-30.3-38.2c-18.1,0-30.1,14-30.1,38.2v0.2
\t\tc0,24.4,12.1,38.4,30.4,38.4C741.7,143.4,753.5,129.2,753.5,105.1z"/>
\t<path class="st51" d="M788.7,53.2h16.4v16.7h0.3c3.4-11.5,13-18.7,25.2-18.7c2.9,0,5.5,0.5,7,0.7v16.8c-1.5-0.5-5.1-0.9-9.3-0.9
\t\tc-13.4,0-22.2,9.6-22.2,24.3v64.8h-17.3V53.2H788.7z"/>
\t<path class="st51" d="M851.5,53.2h17.4v14.7h0.3c5.5-9.6,16.1-16.7,32-16.7c22.7,0,35.8,14.7,35.8,38.3v67.3h-17.3V92.5
\t\tc0-16.8-7.8-26.1-23.8-26.1c-16.4,0-27.1,11.6-27.1,29.4v61h-17.3L851.5,53.2L851.5,53.2z"/>
\t<path class="st51" d="M958.5,146.8c0-6.4,5-11.4,11.4-11.4s11.4,5,11.4,11.4s-5,11.4-11.4,11.4S958.5,153.2,958.5,146.8z"/>
\t<path class="st51" d="M991.9,104.9L991.9,104.9c0-32.7,19-53.7,47.8-53.7c25.9,0,41.1,17.1,43.1,34.9l0.1,0.8H1066l-0.2-0.8
\t\tc-2.6-10.3-11-19.5-26.2-19.5c-18.1,0-30.1,14.9-30.1,38.4v0.1c0,24.1,12.3,38.3,30.3,38.3c14.1,0,23.3-7.9,26.1-19.5l0.2-0.8h17.1
\t\tl-0.1,0.7c-2.8,19.6-19.8,35-43.1,35C1010.5,158.8,991.9,137.9,991.9,104.9z"/>
\t<path class="st51" d="M1094.2,105.1v-0.2c0-33,19.3-53.6,48-53.6s48.1,20.6,48.1,53.6v0.2c0,33.1-19.3,53.9-48,53.9
\t\tC1113.4,158.8,1094.2,138,1094.2,105.1z M1172.3,105.1v-0.2c0-24.3-12-38.2-30.3-38.2c-18.1,0-30.1,14-30.1,38.2v0.2
\t\tc0,24.4,12.1,38.4,30.4,38.4C1160.5,143.4,1172.3,129.2,1172.3,105.1z"/>
\t<path class="st51" d="M1207.4,53.2h17.3v14.7h0.3c5.5-9.6,16.1-16.7,32-16.7c22.7,0,35.8,14.7,35.8,38.3v67.3h-17.3V92.5
\t\tc0-16.8-7.8-26.1-23.8-26.1c-16.4,0-27.1,11.6-27.1,29.4v61h-17.3V53.2H1207.4z"/>
\t<path class="st51" d="M1315.1,53.2h17.3v14.7h0.3c5.5-9.6,16.1-16.7,32-16.7c22.7,0,35.8,14.7,35.8,38.3v67.3h-17.2V92.5
\t\tc0-16.8-7.8-26.1-23.8-26.1c-16.4,0-27.1,11.6-27.1,29.4v61h-17.2V53.2H1315.1z"/>
\t<path class="st51" d="M1417.5,105.4L1417.5,105.4c0-32.2,18.4-54.2,46.6-54.2c28.2,0,45.6,21,45.6,52.1v6.4h-74.6
\t\tc0.3,21.3,12.1,33.8,30.6,33.8c13.2,0,22.5-6.5,25.4-15.5l0.3-0.9h17.1l-0.2,0.9c-3.4,17.1-20.3,30.7-42.9,30.7
\t\tC1435.4,158.8,1417.5,138,1417.5,105.4z M1435.3,96.1h56.7c-1.7-20.2-12.8-29.6-28-29.6C1449,66.4,1437.2,76.8,1435.3,96.1z"/>
\t<path class="st51" d="M1522,104.9L1522,104.9c0-32.7,19-53.7,47.8-53.7c25.9,0,41.1,17.1,43.1,34.9l0.1,0.8h-16.8l-0.2-0.8
\t\tc-2.6-10.3-11-19.5-26.2-19.5c-18.1,0-30.1,14.9-30.1,38.4v0.1c0,24.1,12.3,38.3,30.3,38.3c14.1,0,23.3-7.9,26.1-19.5l0.2-0.8h17.1
\t\tl-0.1,0.7c-2.8,19.6-19.8,35-43.1,35C1540.7,158.8,1522,137.9,1522,104.9z"/>
\t<path class="st51" d="M1634.6,130.5V67.6h-15.2V53.2h15.2v-28h18v28h19.6v14.3h-19.6v62.8c0,11.1,4.9,14.1,14.1,14.1
\t\tc2.1,0,4.1-0.3,5.5-0.3v14c-2.1,0.3-6,0.7-9.3,0.7C1644,158.8,1634.6,150.2,1634.6,130.5z"/>
</g>
<g>
\t<path class="st51" d="M665.9,201h9.1v23.8h0.2l20.3-23.8h10.4l-19.4,22.4l21,28.3h-11l-16.4-22.4l-5.1,6v16.3h-9.1V201z"/>
\t<path class="st51" d="M708.5,226.4L708.5,226.4c0-16.2,9.2-26.2,23.8-26.2c11.7,0,20.2,7.3,21.6,17.6v0.4H745l-0.2-0.7
\t\tc-1.5-5.8-5.9-9.4-12.6-9.4c-8.9,0-14.5,7.1-14.5,18.3l0,0c0,11.3,5.7,18.4,14.5,18.4c6.5,0,11-3.5,12.7-9.7l0.1-0.4h8.9l-0.1,0.4
\t\tc-1.3,10.4-9.9,17.6-21.6,17.6C717.7,252.6,708.5,242.6,708.5,226.4z"/>
\t<path class="st51" d="M760.8,201h32.8v7.6h-23.7v13.7h22.5v7.3h-22.5V244h23.8v7.6h-32.8L760.8,201L760.8,201z"/>
\t<path class="st51" d="M801.5,201h9.1v43.1h23v7.6h-32.1V201z"/>
\t<path class="st51" d="M840.1,201h9.1v43.1h23v7.6h-32.1V201z"/>
\t<path class="st51" d="M891.1,241.4L891.1,241.4c0-6.3,4.9-10.2,13.3-10.7l10.5-0.6V227c0-4.5-2.9-7.2-8-7.2c-4.7,0-7.7,2.2-8.4,5.5
\t\tl-0.1,0.3h-5.9v-0.4c0.5-6,5.8-10.9,14.4-10.9c8.6,0,14.1,4.7,14.1,12v25.3h-6.1v-5.9h-0.1c-2.1,4-6.5,6.6-11.4,6.6
\t\tC896,252.4,891.1,248.1,891.1,241.4z M904.7,247.1c5.8,0,10.2-4,10.2-9.3v-3l-9.8,0.6c-4.9,0.3-7.7,2.5-7.7,5.9v0.1
\t\tC897.4,244.9,900.3,247.1,904.7,247.1z"/>
\t<path class="st51" d="M927,233.5L927,233.5c0-11.6,6.3-19,15.4-19c5.2,0,9.4,2.6,11.7,6.8h0.1V201h6.1v50.7h-6.1v-6.2h-0.1
\t\tc-2.3,4.3-6.4,6.9-11.6,6.9C933.2,252.4,927,245,927,233.5z M954.3,233.5L954.3,233.5c0-8.2-4.4-13.6-10.6-13.6
\t\tc-6.5,0-10.5,5.2-10.5,13.5l0,0c0,8.4,4,13.6,10.5,13.6C949.9,247,954.3,241.7,954.3,233.5z"/>
\t<path class="st51" d="M965.5,215.2h6.4l10.1,30.1h0.1l10.1-30.1h6.4l-13.4,36.6h-6.3L965.5,215.2z"/>
\t<path class="st51" d="M1001.2,241.4L1001.2,241.4c0-6.3,4.9-10.2,13.3-10.7l10.5-0.6V227c0-4.5-2.9-7.2-8-7.2
\t\tc-4.7,0-7.7,2.2-8.4,5.5l-0.1,0.3h-6v-0.4c0.5-6,5.8-10.9,14.4-10.9s14.1,4.7,14.1,12v25.3h-6.2v-5.9h-0.1c-2.1,4-6.5,6.6-11.4,6.6
\t\tC1006.1,252.4,1001.2,248.1,1001.2,241.4z M1014.7,247.1c5.8,0,10.2-4,10.2-9.3v-3l-9.8,0.6c-4.9,0.3-7.7,2.5-7.7,5.9v0.1
\t\tC1007.5,244.9,1010.4,247.1,1014.7,247.1z"/>
\t<path class="st51" d="M1038.8,215.2h6.1v5.2h0.1c2-3.4,5.7-5.9,11.2-5.9c8,0,12.7,5.2,12.7,13.5v23.7h-6v-22.6
\t\tc0-5.9-2.8-9.2-8.4-9.2c-5.8,0-9.5,4.1-9.5,10.3v21.5h-6.1L1038.8,215.2L1038.8,215.2z"/>
\t<path class="st51" d="M1075,233.4L1075,233.4c0-11.6,6.7-18.9,16.9-18.9c9.1,0,14.5,6,15.3,12.3v0.3h-6l-0.1-0.3
\t\tc-0.9-3.7-3.9-6.9-9.2-6.9c-6.4,0-10.6,5.3-10.6,13.6l0,0c0,8.5,4.4,13.5,10.7,13.5c5,0,8.2-2.8,9.2-6.9l0.1-0.3h6v0.2
\t\tc-1,7-7,12.3-15.3,12.3C1081.5,252.4,1075,245.1,1075,233.4z"/>
\t<path class="st51" d="M1111,233.6L1111,233.6c0-11.4,6.5-19.1,16.5-19.1s16.1,7.4,16.1,18.4v2.2h-26.3c0.1,7.5,4.3,11.9,10.8,11.9
\t\tc4.6,0,7.9-2.3,9-5.4l0.1-0.3h6l-0.1,0.4c-1.2,6-7.2,10.8-15.2,10.8C1117.4,252.4,1111,245.1,1111,233.6z M1117.3,230.3h20
\t\tc-0.6-7.1-4.5-10.4-9.9-10.4C1122.2,219.9,1118,223.5,1117.3,230.3z"/>
\t<path class="st51" d="M1147.9,233.5L1147.9,233.5c0-11.6,6.3-19,15.4-19c5.2,0,9.4,2.6,11.7,6.8h0.1V201h6.1v50.7h-6.1v-6.2h-0.1
\t\tc-2.3,4.3-6.4,6.9-11.6,6.9C1154,252.4,1147.9,245,1147.9,233.5z M1175.2,233.5L1175.2,233.5c0-8.2-4.4-13.6-10.6-13.6
\t\tc-6.5,0-10.5,5.2-10.5,13.5l0,0c0,8.4,4,13.6,10.5,13.6C1170.7,247,1175.2,241.7,1175.2,233.5z"/>
\t<path class="st51" d="M1203.3,226.4L1203.3,226.4c0-16,9-26.2,23.2-26.2c10.5,0,18.7,6.4,20.7,16l0.1,0.4h-6.4l-0.2-0.8
\t\tc-2-6.2-7-9.7-14.1-9.7c-10.1,0-16.7,8-16.7,20.3l0,0c0,12.3,6.6,20.4,16.7,20.4c7.1,0,12-3.5,14.2-10.2l0.1-0.3h6.4l-0.1,0.4
\t\tc-1.9,9.7-10.1,16-20.7,16C1212.4,252.6,1203.3,242.3,1203.3,226.4z"/>
\t<path class="st51" d="M1254.7,201h18.1c9.5,0,16.3,6.6,16.3,16.2v0.1c0,9.6-6.8,16.3-16.3,16.3h-11.7v18.1h-6.3L1254.7,201
\t\tL1254.7,201z M1260.9,206.6V228h10.1c7.2,0,11.4-4,11.4-10.7v-0.1c0-6.7-4.2-10.7-11.4-10.7h-10.1V206.6z"/>
\t<path class="st51" d="M1307.1,201h6.3l18.7,50.7h-6.6l-5.1-14.5h-20.2l-5.1,14.5h-6.6L1307.1,201z M1301.9,231.8h16.5l-8.2-23.2
\t\th-0.1L1301.9,231.8z"/>
</g>
`,
]
