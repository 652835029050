import {getServiceType} from '../services/api.js'

export const serviceType = {
  state: () => ({
    serviceType: []
  }),
  mutations: {
    setServiceType (state, items) {
      state.serviceType = items
    }
  },
  actions: {
    loadServiceType({commit})  {
      return getServiceType().then(response => {
        commit("setServiceType", response.data[0].result.values)
        return response
      })
    },
  },
  getters: {
    getAllServiceType: state => {
      return state.serviceType
    },
    getServiceType: state => id => {
      let item = state.serviceType.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0]
      }
      return null
    },
    getServiceTypeName: state => id => {
      if (state.serviceType) {
        let item = state.serviceType.filter(item => {
          return item.ID === id
        })
        if (item[0]) {
          return item[0].TypeName
        }
      }
      return null
    }
  }
}
