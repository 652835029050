import {auth as authRequest, getCurrency} from '../services/api.js'
import router from "../router";

export const auth = {
  state: () => ({
    session_id: ""
  }),
  mutations: {
    setSession (state, session_id) {
      state.session_id = session_id
    }
  },
  actions: {
    auth({commit, dispatch}, payload)  {
      return authRequest(payload.login, payload.password).then(response => {
        commit("setSession", response.data[0].result.session_id)
        commit("setMenuItems", response.data[0].result.menu)

        dispatch("loadUserFunctions")
        dispatch("loadUserRoles")

        dispatch("loadCenter")

        if (process.env.VUE_APP_NAME === 'asms') {
          dispatch("loadCurrency")
          dispatch("loadContracts")
          dispatch("loadPlatform")
          dispatch("loadServicePrice")
          dispatch("loadServiceType")
        }

        return response
      })
    },
    logout({commit})  {
      commit("setSession", '')
      commit("setMenuItems", [])
      commit("setFunctionItems", '')
      commit("setRolesItems", '')
      commit("setCurrency", '')
      commit("setPlatform", '')
      commit("setServicePrice", '')
      commit("setServiceType", '')
      commit("setCenter", '')
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.session_id !== ""
    }
  }
}
