
function getGroupIcon(groupId) {
  switch (groupId) {
    case 'configurator':
      return 'cil-cog'
    case 'reports':
    case 'report':
      return 'cil-spreadsheet'
    default:
      return 'cil-puzzle'
  }
}

export const navigation = {
  state: () => ({
    nav: []
  }),
  mutations: {
    setMenuItems (state, items) {
      state.nav = items.map(item => {
        let nav_item = {
          component: 'CNavGroup',
          name: item.text,
          to: '/'+item.id,
          icon: getGroupIcon(item.id)
        }

        nav_item['items'] = item.data.map(itemData => {
          return {
            component: 'CNavItem',
            name: itemData.text,
            to: '/'+itemData.id.toLowerCase().replace("-","/"),
            props: {
              method: itemData.action,
              controller: itemData.controller
            }
          }
        })
        return nav_item
      })

    }
  },

  getters: {
    nav: state => {
      return state.nav
    }
  }
}
