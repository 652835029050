import {getCenter} from '../services/api.js'

export const center = {
  state: () => ({
    center: []
  }),
  mutations: {
    setCenter (state, items) {
      state.center = items
    }
  },
  actions: {
    loadCenter({commit})  {
      return getCenter().then(response => {
        commit("setCenter", response.data[0].result.values)
        return response
      })
    },
  },
  getters: {
    getAllCenter: state => {
      return state.center
    },
    getCenter: state => id => {
      let item = state.center.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0]
      }
      return null
    },
    getCenterName: state => id => {
      let item = state.center.filter(item => { return item.ID === id })
      if (item[0]) {
        return item[0].Name
      }
      return null
    }
  }
}
